// --- Checkout ---

var checkoutRows = document.querySelectorAll('form.woocommerce-checkout .form-row');

if (checkoutRows.length) {

    checkoutRows.forEach(function (el) {
        
        el.classList.remove('form-row');
        
    });

};

var couponRows = document.querySelectorAll('form.checkout_coupon .form-row');

if (couponRows.length) {

    couponRows.forEach(function (el) {
        
        el.classList.remove('form-row');
        
    });

};

var addressCols = document.querySelectorAll('.woocommerce-account .woocommerce-Address');

if (addressCols.length) {

    addressCols.forEach(function (el) {
        
        el.classList.remove('col-1');
        el.classList.remove('col-2');
        
    });

};

var addressRows = document.querySelectorAll('.woocommerce-MyAccount-content .form-row');

if (addressRows.length) {

    addressRows.forEach(function (el) {
        
        el.classList.remove('form-row');
        
    });

};

// --- Amazon Pay ---

var aPayCols1 = document.querySelector('#amazon_customer_details .col-1');
var aPayCols2 = document.querySelector('#amazon_customer_details .col-2');

if (aPayCols1) {

    aPayCols1.classList.remove('col-1');
    aPayCols2.classList.remove('col-2');

};


// --- thank you page ---


var thankyouRow = document.querySelectorAll('.thankyou-page .woocommerce-columns');
var thankyouCols = document.querySelectorAll('.thankyou-page .woocommerce-column');

if (thankyouCols.length) { 

    thankyouRow.forEach(function (el) {

        el.classList.add('row');
        
    });
    

    thankyouCols.forEach(function (el) {
        
        el.classList.remove('col-1');
        el.classList.remove('col-2');
        el.classList.add('col-12');
        el.classList.add('col-md-6');
        
    });

};

document.addEventListener('DOMContentLoaded',function() {
    document.querySelector("#billing_cf").onchange=check;
},false);

function check(event) {
    var cf = CodiceFiscale.validate(event.target.value);
    var wrapper = document.querySelector("#billing_cf_field");
    document.getElementById("place_order").disabled = false;

    if(event.target.value === ''){
        wrapper.classList.remove('woocommerce-validated'); // success
        wrapper.classList.remove('woocommerce-invalid'); // error
        document.getElementById("place_order").disabled = false;
        return
    }

    if(cf === null){
        wrapper.classList.remove('woocommerce-invalid'); // error
        wrapper.classList.add('woocommerce-validated'); // success
    }else{
        wrapper.classList.remove('woocommerce-validated'); // success
        wrapper.classList.add('woocommerce-invalid'); // error
        document.getElementById("place_order").disabled = true;
    }
}

// This source code is now part of the PHPLint project, see:
// http://cvs.icosaedro.it:8080/viewvc/public/phplint/stdlib/it/icosaedro/web
// Test code also available under the test/ directory of that project.

/**
 * Class for Italian Codice Fiscale normalization, formatting and checking.
 * @author Umberto Salsi <salsi@icosaedro.it>
 * @version $Date: 2020/01/23 10:35:20 $
 */
 class CodiceFiscale
 {
 
     /**
      * Normalizes a CF by removing white spaces and converting to upper-case.
      * @param string cf Raw CF, possibly with spaces.
      * @return string Normalized CF.
      */
     static normalize(cf)
     {
         return cf.replace(/\s/g, "").toUpperCase();
     }
 
 
 
     /**
      * Returns the formatted CF. Currently does nothing but normalization.
      * @param string cf Raw CF, possibly with spaces.
      * @return string Formatted CF.
      */
     static format(cf)
     {
         return this.normalize(cf);
     }
     
     
     /**
      * Validates a regular CF.
      * @param string cf Normalized, 16 characters CF.
      * @return string Null if valid, or string describing why this CF must be
      * rejected.
      */
     static PRIVATE_validate_regular(cf)
     {
         if( ! /^[0-9A-Z]{16}$/.test(cf) )
             return "Invalid characters.";
         var s = 0;
         var even_map = "BAFHJNPRTVCESULDGIMOQKWZYX";
         for(var i = 0; i < 15; i++){
             var c = cf[i];
             var n = 0;
             if( "0" <= c && c <= "9" )
                 n = c.charCodeAt(0) - "0".charCodeAt(0);
             else
                 n = c.charCodeAt(0) - "A".charCodeAt(0);
             if( (i & 1) === 0 )
                 n = even_map.charCodeAt(n) - "A".charCodeAt(0);
             s += n;
         }
         if( s%26 + "A".charCodeAt(0) !== cf.charCodeAt(15) )
             return "Invalid checksum.";
         return null;
     }
     
     
     /**
      * Validates a temporary CF.
      * @param string cf Normalized, 11 characters CF.
      * @return string Null if valid, or string describing why this CF must be
      * rejected.
      */
     static PRIVATE_validate_temporary(cf)
     {
         if( ! /^[0-9]{11}$/.test(cf) )
             return "Invalid characters.";
         var s = 0;
         for(var i = 0; i < 11; i++ ){
             var n = cf.charCodeAt(i) - "0".charCodeAt(0);
             if( (i & 1) === 1 ){
                 n *= 2;
                 if( n > 9 )
                     n -= 9;
             }
             s += n;
         }
         if( s % 10 !== 0 )
             return "Invalid checksum.";
         return null;
     }
     
     
     /**
      * Verifies the basic syntax, length and control code of the given CF.
      * @param string cf Raw CF, possibly with spaces.
      * @return string Null if valid, or string describing why this CF must be
      * rejected.
      */
     static validate(cf)
     {
         cf = this.normalize(cf);
         if( cf.length === 0 )
             return "Empty.";
         else if( cf.length === 16 )
             return this.PRIVATE_validate_regular(cf);
         else if( cf.length === 11 )
             return this.PRIVATE_validate_temporary(cf);
         else
             return "Invalid length.";
     }
 
 }

 
// --- account page ---

var accountCol1 = document.querySelectorAll('.woocommerce-account .u-column1');
var accountCol2 = document.querySelectorAll('.woocommerce-account .u-column2');

if (accountCol1.length) { 

    accountCol1.forEach(function (el) {   
        el.classList.remove('col-1');
    });

    accountCol2.forEach(function (el) {   
        el.classList.remove('col-2');
    });
  
};

// --- Custom Containers ---

var stretchLeft = document.querySelectorAll('.col-stretch-l');
var stretchRight = document.querySelectorAll('.col-stretch-r');

var mediaQueryMD = window.matchMedia('(min-width: 1024px)')

function doStretchLeft() {
    
    var screenWidth = document.body.clientWidth;
    var containerWidth = document.querySelector('.container').clientWidth;

    stretchLeft.forEach(function (el) {

        el.style.marginLeft = - (screenWidth - containerWidth) / 2 + 'px';

    });

}

function doStretchRight() {

    var screenWidth = document.body.clientWidth;
    var containerWidth = document.querySelector('.container').clientWidth;

    stretchRight.forEach(function (el) {

        el.style.marginRight = - (screenWidth - containerWidth) / 2 + 'px';

    });

}

if (mediaQueryMD.matches) {

    if (stretchLeft.length) {

        document.addEventListener('DOMContentLoaded', doStretchLeft);
        window.addEventListener('resize', doStretchLeft);

    };

    if (stretchRight.length) {

        document.addEventListener('DOMContentLoaded', doStretchRight);
        window.addEventListener('resize', doStretchRight);

    };

};